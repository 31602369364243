import React, { Component } from 'react';
import { graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Layout } from '../components';
import { VideoBlock, ProjectPreview } from '../components/common';
import './index.scss';

class Index extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      projectSlug,
    } = siteMetadata;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      featuredVideo,
      projectShowcase,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    const hasVideo = !isEmpty(featuredVideo);
    return (
      <Layout location={location} seoData={seoData}>
        {hasVideo && (
          <section className="reel">
            <VideoBlock
              videoUrl={featuredVideo[0].videoUrl.url}
              videoProvider={featuredVideo[0].videoProvider}
            />
          </section>
        )}
        {projectShowcase && (
          <section className="project-showcase">
            <div className="projects">
              {projectShowcase.map((projectLink) => {
                const project = get(projectLink, 'project.document');
                if (!project) return null;
                return (
                  <ProjectPreview
                    key={project.uid}
                    projectSlug={projectSlug}
                    uid={project.uid}
                    {...project.data}
                  />
                );
              })}
            </div>
          </section>
        )}
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        projectSlug,
      }
    },
    page: prismicHome {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        featuredVideo: featured_video {
          videoProvider: video_provider
          videoUrl: video_url {
            url
          }
        }
        projectShowcase: project_showcase {
          project {
            document {
              ... on PrismicProject {
                uid
                data {
                  client {
                    text
                  }
                  projectName: project_name {
                    text
                  }
                 featuredImage: featured_image {
                   fluid(maxWidth: 1000) {
                     ...GatsbyPrismicImageFluid
                   }
                   url
                   alt
                  }
                  openingDescription: opening_description {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
